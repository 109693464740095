import pizzaImage from'../images/pizza.png';
import burgerImg from '../images/burger.png';
import cocaImg from '../images/coca.png';
import saladImg from '../images/salad.png';
import waterImg from '../images/water.png';
import iceCreamImg from '../images/icecream.png';
import kebabImg from '../images/kebab.png';

export function getData(){
    return [
        {title: "Pizza", price:17.99, Image: pizzaImage},
        {title: "Burger", price:10.99, Image: burgerImg},
        {title: "Coca", price:11.99, Image: cocaImg},
        {title: "Salad", price:12.99, Image: saladImg},
        {title: "Bottle of water", price:13.99, Image: waterImg},
        {title: "Icecream", price:15.99, Image: iceCreamImg},
        {title: "Kebab", price:14.99, Image: kebabImg},
];
}
